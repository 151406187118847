import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Icon from "@mdi/react";
import { mdiInformationVariant, mdiInformation } from "@mdi/js";

import InputMask from "react-input-mask";

export default function ModalForm({ reportObj }) {
  let rawDate = new Date(reportObj.datum);

  let dag_raw = rawDate.getDate();
  let maand_raw = rawDate.getMonth() + 1;
  let jaar = rawDate.getFullYear();
  let date, month;

  if (dag_raw < 10) {
    date = "0" + dag_raw.toString();
  } else {
    date = dag_raw.toString();
  }
  if (maand_raw < 10) {
    month = "0" + maand_raw.toString();
  } else {
    month = maand_raw.toString();
  }

  let constDate = jaar + "-" + month + "-" + date;

  const reportid = reportObj.pkey;
  const constDat = constDate;
  const medewerker = reportObj.medewerker;
  const locatie = reportObj.locatie;
  const gemeente = reportObj.gemeente;
  const zaaknummer = reportObj.zaaknummer;
  const omschrijving = reportObj.omschrijving;
  const ernstq1 = reportObj.score_e1;
  const ernstq2 = reportObj.score_e2;
  const ernstq3 = reportObj.score_e3;
  const ernstq4 = reportObj.score_e4;
  const ernstq5 = reportObj.score_e5;
  const ernstq6 = reportObj.score_e6;
  const ernstq7 = reportObj.score_e7;
  const ernstq8 = reportObj.score_e8;
  const gedragq1 = reportObj.score_g1;
  const gedragq2 = reportObj.score_g2;
  const gedragq3 = reportObj.score_g3;
  const gedragq4 = reportObj.score_g4;
  const gedragq5 = reportObj.score_g5;
  const gedragq6 = reportObj.score_g6;
  const strat1 = reportObj.score_s1 || 0;
  const strat2 = reportObj.score_s2 || 0;
  const strat3 = reportObj.score_s3 || 0;
  const strat5 = reportObj.score_s5 || 0;
  const strat6 = reportObj.score_s6 || 0;
  const strat7 = reportObj.score_s7 || 0;
  const G4Toelichting = reportObj.toelichting_e4;
  const G6Toelichting = reportObj.toelichting_e6;
  const ernstq7aClass = reportObj.ernstq7atekst;
  const gedragq7Class = reportObj.gedragq7tekst;
  const gedragq8Class = reportObj.gedragq8tekst;
  const gedragq9Class = `G9${reportObj.gedragq9tekst}`;
  const gScoreLetter = reportObj.gscore_letter;
  const eScoreCijfer = reportObj.escore_cijfer;
  const gscoreCalcTotal = reportObj.gscore_calc_total;
  const escoreCalcTotal = reportObj.escore_calc_total;
  const gAfwijkingClass = `gAfw${reportObj.gscore_afwijken}`;
  const gAfwijking = reportObj.gscore_afwijken;
  const gAfwijkingToelichting = reportObj.gscore_mutareden;
  const eAfwijkingClass = `eAfw${reportObj.escore_afwijken}`;
  const eAfwijking = reportObj.escore_afwijken;
  const eAfwijkingToelichting = reportObj.escore_mutareden;
  const strat4Class = "";
  const strat5Toelichting = "";
  const showInfo1 = "";
  const handleCloseInfo1 = "";
  const infoText = "";

  const [rangeValueStyle, setRangeValueStyle] = useState({
    ernstq1: "range-value",
    ernstq2: "range-value",
    ernstq3: "range-value",
    ernstq4: "range-value",
    ernstq5: "range-value",
    ernstq6: "range-value",
    ernstq7: "range-value",
    ernstq8: "range-value",
    gedragq1: "range-value",
    gedragq2: "range-value",
    gedragq3: "range-value",
    gedragq4: "range-value",
    gedragq5: "range-value",
    gedragq6: "range-value",
    gedragq7: "range-value",
    strat1: "range-value",
    strat2: "range-value",
    strat3: "range-value",
    strat4: "range-value",
    strat5: "range-value",
    strat6: "range-value",
    strat7: "range-value",
  });

  const [sliderStyle, setSliderStyle] = useState({
    ernstq1: "form-control-range custom-range checked",
    ernstq2: "form-control-range custom-range checked",
    ernstq3: "form-control-range custom-range checked",
    ernstq4: "form-control-range custom-range checked",
    ernstq5: "form-control-range custom-range checked",
    ernstq6: "form-control-range custom-range checked",
    ernstq7: "form-control-range custom-range checked",
    ernstq8: "form-control-range custom-range checked",
    gedragq1: "form-control-range custom-range checked",
    gedragq2: "form-control-range custom-range checked",
    gedragq3: "form-control-range custom-range checked",
    gedragq4: "form-control-range custom-range checked",
    gedragq5: "form-control-range custom-range checked",
    gedragq6: "form-control-range custom-range checked",
    gedragq7: "form-control-range custom-range checked",
    strat1: "form-control-range custom-range checked",
    strat2: "form-control-range custom-range checked",
    strat3: "form-control-range custom-range checked",
    strat4: "form-control-range custom-range checked",
    strat5: "form-control-range custom-range checked",
    strat6: "form-control-range custom-range checked",
    strat7: "form-control-range custom-range checked",
  });

  const handleShowInfo1 = "";

  return (
    <>
      <div className="container d-flex justify-content-center">
        <div className="col-md-10 login-form my-3">
          <form>
            <h3 className="text-right my-5 h5">MeldingsID {reportid}</h3>
            <h2 className="text-center my-5 h3">Algemene gegevens</h2>
            <div className="form-group row">
              <label htmlFor="constDat" className="col-sm-4 col-form-label">
                Datum constatering
              </label>
              <div className="col-sm-8">
                <input
                  className="form-control"
                  name="constDat"
                  value={constDat}
                  type="date"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="userId" className="col-sm-4 col-form-label">
                Medewerker
              </label>
              <div className="col-sm-8">
                <input name="medewerker" value={medewerker} className="form-control" disabled />
              </div>
            </div>
            <h2 className="text-center my-5">De overtreding zelf</h2>
            <div className="form-group row">
              <div className="form-group col-md-12 col-sm-12 col-lg-6">
                <label htmlFor="locatie">Op welke locatie is de overtreding geconstateerd?</label>
                <input className="form-control" name="locatie" value={locatie} disabled />
              </div>
              <div className="form-group col-md-6 col-sm-6 col-lg-3">
                <label htmlFor="gemeente">Gemeente</label>
                <input className="form-control" name="gemeente" value={gemeente} disabled />
              </div>
              <div className="form-group col-md-6 col-sm-6 col-lg-3">
                <label htmlFor="zaaknummer">Zaaknummer</label>
                <InputMask
                  mask="Z-9999-999999"
                  maskChar=" "
                  name="zaaknummer"
                  value={zaaknummer}
                  placeholder="Z-____-______"
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="omschrijving">
                  Geef een bondige omschrijving van de geconstateerde overtreding (max. 400 tekens)
                </label>
                <textarea
                  name="omschrijving"
                  value={omschrijving}
                  className="form-control"
                  rows="3"
                  disabled
                />
              </div>
            </div>
            <h2 className="text-center my-5 h3">De vragen (ernst)</h2>
            <h3 className="my-2 h4">Schade aan milieu, water en/of natuur</h3>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="ernstq1">
                  Wat is de omvang van de <u>al onstane</u> mileu/water en natuurschade?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Nihil</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block mt-4">Nihil</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq1} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq1}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq1"
                        value={ernstq1 === "" ? 0 : ernstq1}
                        className={sliderStyle.ernstq1}
                        id="ernstq1"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block mt-4">Enorm</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label htmlFor="ernstq2">
                  Hoe groot is de kans dat er alsnog of grotere schade ontstaat?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">0%</div>
                  <div className="ml-auto d-flex justify-content-end my-1">100%</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">0%</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq2} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq2}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq2"
                        value={ernstq2 === "" ? 0 : ernstq2}
                        className={sliderStyle.ernstq2}
                        id="ernstq2"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">100%</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label>
                  <u>Als</u> er nog extra schade ontstaat hoe groot zal die dan waarschijnlijk zijn?
                </label>
                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Nihil</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Nihil</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq3} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq3}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq3"
                        value={ernstq3 === "" ? 0 : ernstq3}
                        className={sliderStyle.ernstq3}
                        id="ernstq3"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Enorm</div>
                </div>
              </div>
            </div>
            <h3 className="my-2 h4">Maatschappelijke onrust</h3>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label>
                  Als er schade is of komt, hoe groot is dan de kans dat dit maatschappelijke onrust
                  oplevert?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">0%</div>
                  <div className="ml-auto d-flex justify-content-end my-1">100% / Die is er al</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">0%</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq4} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq4}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq4"
                        value={ernstq4 === "" ? 0 : ernstq4}
                        className={sliderStyle.ernstq4}
                        id="ernstq4"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">100% / Die is er al</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label htmlFor="ernstq5">
                  <u>Als</u> er onrust komt, hoe groot zal die dan zijn?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Nihil</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Nihil</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq5} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq5}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq5"
                        value={ernstq5 === "" ? 0 : ernstq5}
                        className={sliderStyle.ernstq5}
                        id="ernstq5"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Enorm</div>
                </div>
              </div>
            </div>
            <h3 className="my-2 h4">Doden, zieken of gewonden</h3>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label htmlFor="ernstq6">
                  Hoe groot is de kans dat mensen, dieren of planten gewond raken, ziek worden of
                  sterven?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">0%</div>
                  <div className="ml-auto d-flex justify-content-end my-1">100% / Dat is al zo</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">0%</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq6} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq6}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq6"
                        value={ernstq6 === "" ? 0 : ernstq6}
                        className={sliderStyle.ernstq6}
                        id="ernstq6"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">100% / Dat is al zo</div>
                </div>
              </div>
            </div>
            {ernstq6 !== "" && ernstq6 > 0 && (
              <>
                <p>Het betreft dan:</p>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <input
                      type="radio"
                      className="btn-check"
                      name="ernstq7a"
                      id="plantDier"
                      autoComplete="off"
                      hidden
                    />
                    <label
                      className={`btn ${
                        ernstq7aClass === "plantDier" ? "btn-success" : "btn-outline-secondary"
                      } col-sm-4`}
                      htmlFor="plantDier"
                    >
                      Plant en dier
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="ernstq7a"
                      id="mens"
                      autoComplete="off"
                      hidden
                      readOnly
                    />
                    <label
                      className={`btn ${
                        ernstq7aClass === "mens" ? "btn-success" : "btn-outline-secondary"
                      } col-sm-4`}
                      htmlFor="mens"
                    >
                      Mens
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="ernstq7a"
                      id="mensPlantDier"
                      autoComplete="off"
                      hidden
                      readOnly
                    />
                    <label
                      className={`btn ${
                        ernstq7aClass === "mensPlantDier" ? "btn-success" : "btn-outline-secondary"
                      } col-sm-4`}
                      htmlFor="mensPlantDier"
                    >
                      Mens, plant en dier
                    </label>
                  </div>
                </div>
              </>
            )}
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label>
                  <u>Als</u> dat gebeurt, wat is dan de meest waarschijnlijke omvang (aantallen en
                  ernst) daarvan?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Nihil</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Nihil</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq7} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq7}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq7"
                        value={ernstq7 === "" ? 0 : ernstq7}
                        className={sliderStyle.ernstq7}
                        id="ernstq7"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Enorm</div>
                </div>
              </div>
            </div>
            <h3 className="my-2 h4">Tenslotte: ongedaan maken van schade?</h3>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="ernstq8">Kan de schade hersteld worden?</label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Volledig</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Helemaal niet</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Volledig</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq8} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq8}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq8"
                        value={ernstq8 === "" ? 0 : ernstq8}
                        className={sliderStyle.ernstq8}
                        id="ernstq8"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Helemaal niet</div>
                </div>
              </div>
            </div>
            <h2 className="text-center my-5 h3">De vragen (gedrag)</h2>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq1">
                  Hoe voorspelbaar was het op voorhand, voor jou als deskundige, dat er schade zou
                  ontstaan?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Geheel onwaarschijnlijk</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Geheel onwaarschijnlijk</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq1} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq1}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq1"
                        value={gedragq1 === "" ? 0 : gedragq1}
                        className={sliderStyle.gedragq1}
                        id="gedragq1"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Nagenoeg zeker</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq2">
                  Had van de overtreder verwacht mogen worden dat deze dezelfde inschatting zou
                  maken?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq2} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq2}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq2"
                        value={gedragq2 === "" ? 0 : gedragq2}
                        className={sliderStyle.gedragq2}
                        id="gedragq2"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq3">
                  Wist de overtreder al dat er sprake was van een overtreding?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq3} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq3}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq3"
                        value={gedragq3 === "" ? 0 : gedragq3}
                        className={sliderStyle.gedragq3}
                        id="gedragq3"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq4">
                  De overtreder heeft de overtreding begaan om voordeel te behalen.
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq4} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq4}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq4"
                        value={gedragq4 === "" ? 0 : gedragq4}
                        className={sliderStyle.gedragq4}
                        id="gedragq4"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            {G4Toelichting.length > 0 && (
              <div className="form-group row">
                <div className="form-group col-sm-12">
                  <textarea
                    name="G4Toelichting"
                    value={G4Toelichting}
                    id="G4Toelichting"
                    placeholder="Geef aan welke indicaties je hebt"
                    className="form-control"
                    rows="3"
                    disabled
                  />
                </div>
              </div>
            )}
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq5">
                  De overtreder probeerde te voorkomen dat de overtreding werd gezien.
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq5} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq5}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq5"
                        value={gedragq5 === "" ? 0 : gedragq5}
                        className={sliderStyle.gedragq5}
                        id="gedragq5"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq6">
                  Het bedrijf is opgezet om de wet te overtreden (criminele organisatie).
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq6} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq6}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq6"
                        value={gedragq6 === "" ? 0 : gedragq6}
                        className={sliderStyle.gedragq6}
                        id="gedragq6"
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            {G6Toelichting.length > 0 && (
              <div className="form-group row">
                <div className="form-group col-sm-12">
                  <textarea
                    name="G6Toelichting"
                    value={G6Toelichting}
                    id="G6Toelichting"
                    placeholder="Geef aan welke indicaties je hebt"
                    className="form-control"
                    rows="3"
                    disabled
                  />
                </div>
              </div>
            )}
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq7">
                  Meerdere handhavende instanties treden op in de richting van dit bedrijf.
                </label>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <input
                      type="radio"
                      className="btn-check"
                      name="gedragq7"
                      id="gedragq7Ja"
                      autoComplete="off"
                      hidden
                    />
                    <label
                      className={`btn ${
                        gedragq7Class === "gedragq7Ja" ? "btn-success" : "btn-outline-secondary"
                      }  col-sm-4`}
                      htmlFor="gedragq7Ja"
                    >
                      Ja
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gedragq7"
                      id="gedragq7Nee"
                      autoComplete="off"
                      hidden
                    />
                    <label
                      className={`btn ${
                        gedragq7Class === "gedragq7Nee" ? "btn-success" : "btn-outline-secondary"
                      }  col-sm-4`}
                      htmlFor="gedragq7Nee"
                    >
                      Nee
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gedragq7"
                      id="gedragq7Onbekend"
                      autoComplete="off"
                      hidden
                    />
                    <label
                      className={`btn ${
                        gedragq7Class === "gedragq7Onbekend"
                          ? "btn-success"
                          : "btn-outline-secondary"
                      }  col-sm-4`}
                      htmlFor="gedragq7Onbekend"
                    >
                      Niet bekend
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <h2 className="text-center my-5 h3">De omstandigheden (verzachtend / verzwarend)</h2>
            <p>Is legalisatie van de overtreding een mogelijkheid?</p>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq8"
                  id="legalJa"
                  autoComplete="off"
                  hidden
                  readOnly
                />
                <label
                  className={`btn ${
                    gedragq8Class === "legalJa" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="legalJa"
                >
                  Ja
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq8"
                  id="legalNee"
                  autoComplete="off"
                  hidden
                  readOnly
                />
                <label
                  className={`btn ${
                    gedragq8Class === "legalNee" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="legalNee"
                >
                  Nee
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq8"
                  id="legalNvt"
                  autoComplete="off"
                  hidden
                  readOnly
                />
                <label
                  className={`btn ${
                    gedragq8Class === "legalNvt" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="legalNvt"
                >
                  NVT
                </label>
              </div>
            </div>
            <p>Is dezelfde overtreding al eerder geconstateerd bij dit bedrijf?</p>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq9"
                  id="G9Nee"
                  autoComplete="off"
                  hidden
                  readOnly
                />
                <label
                  className={`btn ${
                    gedragq9Class === "G9Nee" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="G9Nee"
                >
                  Nee
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq9"
                  id="G9JaEenmaal"
                  autoComplete="off"
                  hidden
                  readOnly
                />
                <label
                  className={`btn ${
                    gedragq9Class === "G9JaEenmaal" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="G9JaEenmaal"
                >
                  Ja eenmaal
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq9"
                  id="G9JaMeermaal"
                  autoComplete="off"
                  hidden
                  readOnly
                />
                <label
                  className={`btn ${
                    gedragq9Class === "G9JaMeermaal" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="G9JaMeermaal"
                >
                  Ja meermalen
                </label>
              </div>
            </div>
            <h2 className="text-center my-5 h3">Het resultaat</h2>
            <p>
              Volgens de gehanteerde methodiek dient de overtreding gekwalificeerd te worden in
              categorie:{" "}
              <b>
                {gScoreLetter}
                {eScoreCijfer} (G: {gscoreCalcTotal.toFixed(2)}, E: {escoreCalcTotal.toFixed(2)}){" "}
              </b>
              <br />
              Als je dat niet vindt, geeft dan hier aan waar je afwijkt van de scores.
            </p>
            <p>Gedrag</p>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <input
                  type="radio"
                  className="btn-check"
                  name="gAfwijking"
                  id="gAfwA"
                  autoComplete="off"
                  hidden
                  disabled={gScoreLetter !== "A" ? false : true}
                  readOnly
                />
                <label
                  className={`btn ${
                    gAfwijkingClass === "gAfwA" ? "btn-success" : "btn-outline-secondary"
                  } ${gScoreLetter === "A" ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="gAfwA"
                >
                  A
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gAfwijking"
                  id="gAfwB"
                  autoComplete="off"
                  hidden
                  disabled={gScoreLetter !== "B" ? false : true}
                  readOnly
                />
                <label
                  className={`btn ${
                    gAfwijkingClass === "gAfwB" ? "btn-success" : "btn-outline-secondary"
                  } ${gScoreLetter === "B" ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="gAfwB"
                >
                  B
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gAfwijking"
                  id="gAfwC"
                  autoComplete="off"
                  hidden
                  disabled={gScoreLetter !== "C" ? false : true}
                  readOnly
                />
                <label
                  className={`btn ${
                    gAfwijkingClass === "gAfwC" ? "btn-success" : "btn-outline-secondary"
                  } ${gScoreLetter === "C" ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="gAfwC"
                >
                  C
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gAfwijking"
                  id="gAfwD"
                  autoComplete="off"
                  hidden
                  disabled={gScoreLetter !== "D" ? false : true}
                  readOnly
                />
                <label
                  className={`btn ${
                    gAfwijkingClass === "gAfwD" ? "btn-success" : "btn-outline-secondary"
                  } ${gScoreLetter === "D" ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="gAfwD"
                >
                  D
                </label>
              </div>
            </div>
            {gAfwijking !== "" && (
              <div className="form-group row">
                <div className="form-group col-sm-12">
                  <label htmlFor="gAfwijkingToelichting">
                    Wat is doorslaggevend om <br className="d-block d-sm-none" />
                    tot de inschatting {gAfwijking} te komen?
                  </label>
                  <textarea
                    name="gAfwijkingToelichting"
                    value={gAfwijkingToelichting}
                    className="form-control"
                    rows="3"
                    disabled
                  />
                </div>
              </div>
            )}
            <p>Ernst</p>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <input
                  type="radio"
                  className="btn-check"
                  name="eAfwijking"
                  id="eAfw1"
                  autoComplete="off"
                  hidden
                  disabled={eScoreCijfer != 1 ? false : true}
                  readOnly
                />
                <label
                  className={`btn ${
                    eAfwijkingClass === "eAfw1" ? "btn-success" : "btn-outline-secondary"
                  } ${eScoreCijfer == 1 ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="eAfw1"
                >
                  1
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="eAfwijking"
                  id="eAfw2"
                  autoComplete="off"
                  hidden
                  disabled={eScoreCijfer != 2 ? false : true}
                  readOnly
                />
                <label
                  className={`btn ${
                    eAfwijkingClass === "eAfw2" ? "btn-success" : "btn-outline-secondary"
                  } ${eScoreCijfer == 2 ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="eAfw2"
                >
                  2
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="eAfwijking"
                  id="eAfw3"
                  autoComplete="off"
                  hidden
                  disabled={eScoreCijfer != 3 ? false : true}
                  readOnly
                />
                <label
                  className={`btn ${
                    eAfwijkingClass === "eAfw3" ? "btn-success" : "btn-outline-secondary"
                  } ${eScoreCijfer == 3 ? "btn-secondary" : ""} col-sm-3`}
                  htmlFor="eAfw3"
                >
                  3
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="eAfwijking"
                  id="eAfw4"
                  autoComplete="off"
                  hidden
                  disabled={eScoreCijfer != 4 ? false : true}
                  readOnly
                />
                <label
                  className={`btn ${
                    eAfwijkingClass === "eAfw4" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-3 ${eScoreCijfer == 4 ? "btn-secondary" : ""}`}
                  htmlFor="eAfw4"
                >
                  4
                </label>
              </div>
            </div>
            {eAfwijking !== "" && (
              <div className="form-group row">
                <div className="form-group col-sm-12">
                  <label htmlFor="eAfwijkingToelichting">
                    Wat is doorslaggevend om <br className="d-block d-sm-none" /> tot de inschatting{" "}
                    {eAfwijking} te komen?
                  </label>
                  <textarea
                    name="eAfwijkingToelichting"
                    value={eAfwijkingToelichting}
                    className="form-control"
                    rows="3"
                    readOnly
                  />
                </div>
              </div>
            )}

            {strat1 > 0 && (
              <>
                <h2 className="text-center my-5 h3">Over de te kiezen strategie</h2>
                <p>
                  <b>
                    Geef hieronder aan of en in welke mate er sprake is van één of meerdere van
                    onderstaande verzwarende aspecten.
                  </b>
                </p>
                <br /> <br />
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label>
                      Het is waarschijnlijk dat met de overtreding financieel voordeel (winst of
                      besparing) werd verkegen
                      <a
                        className="modal-info-tip"
                        onClick={() =>
                          handleShowInfo1(
                            "Door het (nalaten van) handelen is financieel voordeel behaald of financieel voordeel halen was het doel daarvan."
                          )
                        }
                      >
                        <Icon path={mdiInformationVariant} size={0.9} />
                      </a>
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Geheel niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Geheel niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div className={rangeValueStyle.strat1} id="rangeV" style={{ right: 12 }}>
                            <span>{strat1}</span>
                          </div>
                          <input
                            type="range"
                            name="strat1"
                            value={strat1 === "" ? 0 : strat1}
                            className={sliderStyle.strat1}
                            id="strat1"
                            readOnly
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label>
                      De overtreder vervult een bijzondere positie (voorbeeldfunctie/media/politieke
                      gevoeligheid)
                      <a
                        className="modal-info-tip"
                        onClick={() =>
                          handleShowInfo1(
                            "De overtreder is: een regionaal of landelijk maatschappelijk aansprekende of bekende (rechts)persoon, een overheid, een toonaangevend brancheonderdeel, een certificerende instelling, een persoon die een openbaar ambt bekleedt of de eigen organisatie."
                          )
                        }
                      >
                        <Icon path={mdiInformationVariant} size={0.9} />
                      </a>
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Geheel niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Geheel niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div className={rangeValueStyle.strat2} id="rangeV" style={{ right: 12 }}>
                            <span>{strat2}</span>
                          </div>
                          <input
                            type="range"
                            name="strat2"
                            value={strat2 === "" ? 0 : strat2}
                            className={sliderStyle.strat2}
                            id="strat2"
                            readOnly
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label>
                      Zal een financiële sanctie het beoogde effect sorteren?
                      <a
                        className="modal-info-tip"
                        onClick={() =>
                          handleShowInfo1(
                            "Wanneer een bestuurlijke boete zeker niet geïnd kan worden of zeer waarschijnlijk door de overtreder als (bedrijfs)kosten is ingecalculeerd kies je voor 'zeker niet'"
                          )
                        }
                      >
                        <Icon path={mdiInformationVariant} size={0.9} />
                      </a>
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Zeker wel</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker niet</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div className={rangeValueStyle.strat3} id="rangeV" style={{ right: 12 }}>
                            <span>{strat3}</span>
                          </div>
                          <input
                            type="range"
                            name="strat3"
                            value={strat3 === "" ? 0 : strat3}
                            className={sliderStyle.strat3}
                            id="strat3"
                            readOnly
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                    </div>
                  </div>
                </div>
                <p>
                  Los van gedragingen tijdens de controle door de toezichthouder heeft de overtreder
                  ook andere handelingen gepleegd ter verhulling van de feiten, zoals valsheid in
                  geschrift, corruptie of witwassen.
                </p>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <input
                      type="radio"
                      className="btn-check"
                      name="strat4"
                      id="strat4Nee"
                      autoComplete="off"
                      hidden
                      readOnly
                    />
                    <label
                      className={`btn ${
                        strat4Class === "strat4Nee" ? "btn-success" : "btn-outline-secondary"
                      } col-sm-4`}
                      htmlFor="strat4Nee"
                    >
                      Nee
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="strat4"
                      id="strat4Ja"
                      autoComplete="off"
                      hidden
                      readOnly
                    />
                    <label
                      className={`btn ${
                        strat4Class === "strat4Ja" ? "btn-success" : "btn-outline-secondary"
                      } col-sm-4`}
                      htmlFor="strat4Ja"
                    >
                      Ja
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="strat4"
                      id="strat4Onbekend"
                      autoComplete="off"
                      hidden
                      readOnly
                    />
                    <label
                      className={`btn ${
                        strat4Class === "strat4Onbekend" ? "btn-success" : "btn-outline-secondary"
                      } col-sm-4`}
                      htmlFor="strat4Onbekend"
                    >
                      Onbekend
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label>
                      Er is aantoonbaar hulp ingeroepen van 3-den om de overtreding mogelijk te
                      maken
                      <a
                        className="modal-info-tip"
                        onClick={() =>
                          handleShowInfo1(
                            "De overtreder is bij zijn handelen ondersteund door deskundige derden, zoals vergunningverlenende of certificerende instellingen, keuringinstanties en brancheorganisaties. De handhaver moet onderbouwen op grond van welke aanwijzingen hij het vermoeden heeft dat de deskundige derde op de hoogte was en/of medewerking heeft verleend aan de geconstateerde bevinding(en)."
                          )
                        }
                      >
                        <Icon path={mdiInformationVariant} size={0.9} />
                      </a>
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Zeker niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div className={rangeValueStyle.strat5} id="rangeV" style={{ right: 12 }}>
                            <span>{strat5}</span>
                          </div>
                          <input
                            type="range"
                            name="strat5"
                            value={strat5 === "" ? 0 : strat5}
                            className={sliderStyle.strat5}
                            id="strat5"
                            readOnly
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>
                {strat5 >= 50 && (
                  <div className="form-group row">
                    <div className="form-group col-sm-12">
                      <textarea
                        name="strat5Toelichting"
                        value={strat5Toelichting}
                        id="strat5Toelichting"
                        placeholder="Geef hier aan waaruit de kan worden afgeleid:"
                        className="form-control"
                        rows="3"
                        disabled
                      />
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label>
                      Het is nodig om met handhaving een voorbeeld te stellen voor andere
                      (potentiele) overtreders
                      <a
                        className="modal-info-tip"
                        onClick={() =>
                          handleShowInfo1(
                            "Het doel van de handhaving ligt in het onder de aandacht brengen van het belang van een bepaalde norm bij de branche of bij het bredere publiek. Strafrechtelijke handhaving vindt mede plaats in het kader van normhandhaving of normbevestiging met het oog op grotere achterliggende te beschermen rechtsbelangen. Hierbij speelt de openbaarheid van het strafproces een grote rol. Als in het openbaar, door middel van een onderzoek ter terechtzitting of de publicatie van een persbericht bij een transactie of strafbeschikking, verantwoording wordt afgelegd van gepleegde strafbare feiten krijgt de normhandhaving of normbevestiging het juiste effect."
                          )
                        }
                      >
                        <Icon path={mdiInformationVariant} size={0.9} />
                      </a>
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Geheel niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Geheel niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div className={rangeValueStyle.strat6} id="rangeV" style={{ right: 12 }}>
                            <span>{strat6}</span>
                          </div>
                          <input
                            type="range"
                            name="strat6"
                            value={strat6 === "" ? 0 : strat6}
                            className={sliderStyle.strat6}
                            id="strat6"
                            readOnly
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label>
                      Strafrechtelijke handhaving zou waarschijnlijk relevante extra feiten naar
                      boven brengen
                      <a
                        className="modal-info-tip"
                        onClick={() =>
                          handleShowInfo1(
                            "Strafrechtelijk optreden met toepassing van opsporingsbevoegdheden met het oog op de strafrechtelijke waarheidsvinding en afdoening is gewenst als bijvoorbeeld een controle of inspectie aanwijzingen aan het licht kan brengen dat er meer aan de hand is, maar de bestuursrechtelijke instrumenten ontoereikend zijn om de waarheid aan het licht te brengen."
                          )
                        }
                      >
                        <Icon path={mdiInformationVariant} size={0.9} />
                      </a>
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Geheel niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Geheel niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div className={rangeValueStyle.strat7} id="rangeV" style={{ right: 12 }}>
                            <span>{strat7}</span>
                          </div>
                          <input
                            type="range"
                            name="strat7"
                            value={strat7 === "" ? 0 : strat7}
                            className={sliderStyle.strat7}
                            id="strat7"
                            readOnly
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>

      <Modal show={showInfo1} onHide={handleCloseInfo1} keyboard={true} centered>
        <Modal.Header>
          <Modal.Title>
            <Icon path={mdiInformation} color="#006fb4" size={1.6} />
            <span className="glyphtext-right">{"  "}Informatie</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="form-group row">
              <div className="col-12">{infoText}</div>
            </div>
            <div className="form-group row"></div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary btn-sm" onClick={handleCloseInfo1}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
