import React, { useState, useEffect, useSearchParams, useContext } from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHome, mdiInformationVariant, mdiInformation, mdiAlert } from "@mdi/js";
import InputMask from "react-input-mask";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { UserContext } from "./../context/UserContext";

export default function MainForm({
  setAuth,
  backendURL,
  curDate,
  setUpdateAvailable,
  setUnsentReports,
  waitingServiceWorker,
  setWaitingServiceWorker,
}) {
  const [userContext, setUserContext] = useContext(UserContext);
  const queryParams = new URLSearchParams(window.location.search);
  const queryLocatie = queryParams.get("locatie");
  const queryGemeente = queryParams.get("gemeente");
  const queryZaaknummer = queryParams.get("zaaknummer");

  const [step, setStep] = useState(1);

  React.useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
        registrationsArray[0].update();
        setWaitingServiceWorker(registrationsArray[0].waiting);
      });
      let existingReports = JSON.parse(localStorage.getItem("rapporten"));
      // bestaat er rapporten in localstorage?
      if (existingReports) {
        // is deze array gevuld?
        if (existingReports.length > 0) {
          setUnsentReports(true);
        }
      }
    }
  }, [step]);

  React.useEffect(() => {
    if (waitingServiceWorker) {
      console.log("Update beschikbaar");
      setUpdateAvailable(true);
    }
  }, [waitingServiceWorker, setUpdateAvailable]);

  let startWaarden = {};

  if (queryLocatie) {
    startWaarden.locatie = queryLocatie;
  } else {
    startWaarden.locatie = "";
  }

  if (queryGemeente) {
    startWaarden.gemeente = queryGemeente;
  } else {
    startWaarden.gemeente = "";
  }

  if (queryZaaknummer) {
    startWaarden.zaaknummer = queryZaaknummer;
  } else {
    startWaarden.zaaknummer = "";
  }

  const [showInfo1, setShowInfo1] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [alertText, setAlertText] = useState("");
  const [znoMask, setZnomask] = useState("Z-9999-999999");
  const [znoPlaceholder, setZnoplaceholder] = useState("Z-____-______");
  const [nextButton, setNextButton] = useState(0);
  const [escoreCalcTotal, setEscoreCalcTotal] = useState(0);
  const [gscoreCalcTotal, setGscoreCalcTotal] = useState(37.5);
  const [G6G7Maxed, setG6G7Maxed] = useState(0);
  const [multipliers, setMultipliers] = useState("");
  const [gemeenten, setGemeenten] = useState([]);
  const [ernstq7aClass, setErnstq7aClass] = useState("");
  const [gedragq7Class, setGedragq7Class] = useState("");
  const [gedragq8Class, setGedragq8Class] = useState("");
  const [gedragq9Class, setGedragq9Class] = useState("");
  const [gAfwijkingClass, setGAfwijkingClass] = useState("");
  const [eAfwijkingClass, setEAfwijkingClass] = useState("");
  const [disableSend, setDisableSend] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [sliderStyle, setSliderStyle] = useState({
    ernstq1: "form-control-range custom-range",
    ernstq2: "form-control-range custom-range",
    ernstq3: "form-control-range custom-range",
    ernstq4: "form-control-range custom-range",
    ernstq5: "form-control-range custom-range",
    ernstq6: "form-control-range custom-range",
    ernstq7: "form-control-range custom-range",
    ernstq8: "form-control-range custom-range",
    gedragq1: "form-control-range custom-range",
    gedragq2: "form-control-range custom-range",
    gedragq3: "form-control-range custom-range",
    gedragq4: "form-control-range custom-range",
    gedragq5: "form-control-range custom-range",
    gedragq6: "form-control-range custom-range",
  });
  const [rangeValueStyle, setRangeValueStyle] = useState({
    ernstq1: "range-value-hidden",
  });
  const [inputs, setInputs] = useState({
    constDat: curDate,
    medewerker: `${userContext.details.firstname} ${userContext.details.lastname}`,
    locatie: startWaarden.locatie,
    zaaknummer: startWaarden.zaaknummer,
    omschrijving: "",
    ernstq1: "",
    ernstq2: "",
    ernstq3: "",
    ernstq4: "",
    ernstq5: "",
    ernstq6: "",
    ernstq7: "",
    ernstq7a: "",
    ernstq7aTekst: "",
    ernstq8: "",
    gedragq1: "",
    gedragq2: "",
    gedragq3: "",
    gedragq4: "",
    gedragq5: "",
    gedragq6: "",
    gedragq7: "",
    gedragq8: "",
    gedragq8Tekst: "",
    gedragq9: "",
    gedragq9Tekst: "",
    gedragq10: "",
    E1Calc: "",
    E2Calc: "",
    E3Calc: "",
    E4Calc: "",
    E5Calc: "",
    E6Calc: "",
    E7Calc: "",
    E8Calc: "",
    E10Calc: "",
    G6Calc: "",
    G7Calc: "",
    G8Calc: "",
    G9Calc: "",
    G4G5Maxed: "",
    G4Toelichting: "",
    G6Toelichting: "",
    G10Calc: "",
    gAfwijking: "",
    eAfwijking: "",
    gemeente: startWaarden.gemeente,
    gemeenteid: "",
    userid: userContext.details.pkey,
    organisationid: userContext.details.organisationid,
  });

  const history = useHistory();
  const cancelReport = () => history.push("/");

  const handleCloseAlert = () => setShowAlert(false);
  const handleShowAlert = (text) => {
    setAlertText(text);
    setShowAlert(true);
  };

  const handleCloseInfo1 = () => setShowInfo1(false);
  const handleShowInfo1 = (text) => {
    setInfoText(text);
    setShowInfo1(true);
  };

  const {
    constDat,
    medewerker,
    locatie,
    zaaknummer,
    omschrijving,
    ernstq1,
    ernstq2,
    ernstq3,
    ernstq4,
    ernstq5,
    ernstq6,
    ernstq7,
    ernstq7a,
    ernstq8,
    gedragq1,
    gedragq2,
    gedragq3,
    gedragq4,
    gedragq5,
    gedragq6,
    gedragq7,
    gedragq8,
    gedragq9,
    gedragq10,
    E1Calc,
    E3Calc,
    E5Calc,
    E7Calc,
    E8Calc,
    E10Calc,
    G1Calc,
    G2Calc,
    G3Calc,
    G4Calc,
    G5Calc,
    G6Calc,
    G7Calc,
    G8Calc,
    G9Calc,
    G4G5Maxed,
    G4Toelichting,
    G6Toelichting,
    G10Calc,
    gAfwijking,
    eAfwijking,
    eAfwijkingToelichting,
    gAfwijkingToelichting,
    gemeente,
    gemeenteid,
  } = inputs;

  const [eScoreCijfer, setEScoreCijfer] = useState("");
  const [gScoreLetter, setGScoreLetter] = useState("");

  // Totaalscores berekenen
  const calcTotals = () => {
    switch (true) {
      case escoreCalcTotal < 25:
        setEScoreCijfer(1);
        break;
      case escoreCalcTotal >= 25 && escoreCalcTotal < 50:
        setEScoreCijfer(2);
        break;
      case escoreCalcTotal >= 50 && escoreCalcTotal < 75:
        setEScoreCijfer(3);
        break;
      case escoreCalcTotal >= 75:
        setEScoreCijfer(4);
        break;
      default:
        break;
    }

    switch (true) {
      case gscoreCalcTotal < 25:
        setGScoreLetter("A");
        break;
      case gscoreCalcTotal >= 25 && gscoreCalcTotal < 50:
        setGScoreLetter("B");
        break;
      case gscoreCalcTotal >= 50 && gscoreCalcTotal < 75:
        setGScoreLetter("C");
        break;
      case gscoreCalcTotal >= 75:
        setGScoreLetter("D");
        break;
      default:
        break;
    }
  };

  // onderstaande functie wordt eenmalig aangeroepen bij het openen van de pagina.
  // deze haalt de bijbehorende multipliers op voor de juiste organisatie.
  const onOpenForm = async () => {
    const start = Date.now();

    setStartTime(start);

    try {
      const response = await fetch(`${backendURL}forms/multipliers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
      });

      const multip = await response.json();

      setMultipliers(multip);
    } catch (error) {
      let multipliers = localStorage.getItem("multipliers");
      setMultipliers(JSON.parse(multipliers));
    }

    try {
      const getGemeenten = await fetch(`${backendURL}forms/gemeenten`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
      });

      const resultGemeenten = await getGemeenten.json();

      setGemeenten(resultGemeenten);
    } catch (error) {
      let collection = localStorage.getItem("gemeenten");
      setGemeenten(JSON.parse(collection));
    }

    if (userContext.details.znomask) {
      setZnomask(userContext.details.znomask);
    }

    if (userContext.details.znoplaceholder) {
      setZnoplaceholder(userContext.details.znoplaceholder);
    }
  };

  const cancelGAfwijking = () => {
    setGAfwijkingClass("");
    setInputs({ ...inputs, gAfwijking: "", gAfwijkingToelichting: "" });
  };

  const cancelEAfwijking = () => {
    setEAfwijkingClass("");
    setInputs({ ...inputs, eAfwijking: "", eAfwijkingToelichting: "" });
  };

  const onChange = (e, targetName) => {
    let inputValue, inputG9, inputG10, inputE9, maxed, g9Tekst;

    switch (targetName) {
      case "gAfwijking":
        switch (e.target.id) {
          case "gAfwA":
            setGAfwijkingClass("gAfwA");
            setInputs({
              ...inputs,
              gAfwijking: "A",
            });
            break;
          case "gAfwB":
            setGAfwijkingClass("gAfwB");
            setInputs({
              ...inputs,
              gAfwijking: "B",
            });
            break;
          case "gAfwC":
            setGAfwijkingClass("gAfwC");
            setInputs({
              ...inputs,
              gAfwijking: "C",
            });
            break;
          case "gAfwD":
            setGAfwijkingClass("gAfwD");
            setInputs({
              ...inputs,
              gAfwijking: "D",
            });
            break;
          default:
            break;
        }
        break;
      case "eAfwijking":
        switch (e.target.id) {
          case "eAfw1":
            setEAfwijkingClass("eAfw1");
            setInputs({
              ...inputs,
              eAfwijking: "1",
            });
            break;
          case "eAfw2":
            setEAfwijkingClass("eAfw2");
            setInputs({
              ...inputs,
              eAfwijking: "2",
            });
            break;
          case "eAfw3":
            setEAfwijkingClass("eAfw3");
            setInputs({
              ...inputs,
              eAfwijking: "3",
            });
            break;
          case "eAfw4":
            setEAfwijkingClass("eAfw4");
            setInputs({
              ...inputs,
              eAfwijking: "4",
            });
            break;
          default:
            break;
        }
        break;
      case "ernstq7a":
        switch (e.target.id) {
          case "plantDier":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              ernstq7a: 0.3,
              ernstq7aTekst: "plantDier",
              E7Calc:
                ((inputs.E6Calc * 1 * (0.3 * 1) * (inputs.ernstq7 * 1)) / 10) * multipliers.E7,
            });
            setErnstq7aClass("plantDier");
            break;
          case "mens":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              ernstq7a: 1.0,
              ernstq7aTekst: "mens",
              E7Calc:
                ((inputs.E6Calc * 1 * (1.0 * 1) * (inputs.ernstq7 * 1)) / 10) * multipliers.E7,
            });
            setErnstq7aClass("mens");
            break;
          case "mensPlantDier":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              ernstq7a: 1.0,
              ernstq7aTekst: "mensPlantDier",
              E7Calc:
                ((inputs.E6Calc * 1 * (1.0 * 1) * (inputs.ernstq7 * 1)) / 10) * multipliers.E7,
            });
            setErnstq7aClass("mensPlantDier");
            break;
          default:
            break;
        }

        break;
      case "gedragq7":
        switch (e.target.id) {
          case "gedragq7Ja":
            inputValue = 100;
            setGedragq7Class("gedragq7Ja");
            break;
          case "gedragq7Nee":
            inputValue = 0;
            setGedragq7Class("gedragq7Nee");
            break;
          case "gedragq7Onbekend":
            inputValue = 0;
            setGedragq7Class("gedragq7Onbekend");
            break;
          default:
            break;
        }
        setInputs({
          ...inputs,
          [e.target.name]: inputValue,
          gedragq7tekst: e.target.id,
          G7Calc: (((inputValue * 1) / 10) * multipliers.G7).toFixed(1) * 1,
        });
        break;
      case "gedragq8":
        switch (e.target.id) {
          case "legalJa":
            inputValue = -25.0;
            setGedragq8Class("legalJa");
            break;
          case "legalNee":
            inputValue = 0;
            setGedragq8Class("legalNee");
            break;
          case "legalNvt":
            inputValue = 0;
            setGedragq8Class("legalNvt");
            break;
          default:
            break;
        }
        setInputs({
          ...inputs,
          gedragq8Tekst: e.target.id,
          [e.target.name]: inputValue,
          G8Calc: inputValue,
        });
        break;
      case "gedragq9":
        switch (e.target.id) {
          case "G9Nee":
            g9Tekst = "Nee";
            inputG9 = 0;
            inputG10 = 0;
            inputE9 = 0;
            setGedragq9Class("G9Nee");
            break;
          case "G9JaEenmaal":
            g9Tekst = "JaEenmaal";
            inputG9 = 25;
            inputG10 = 0;
            inputE9 = 25;
            setGedragq9Class("G9JaEenmaal");
            break;
          case "G9JaMeermaal":
            g9Tekst = "JaMeermaal";
            inputG9 = 25;
            inputG10 = 50;
            inputE9 = 25;
            setGedragq9Class("G9JaMeermaal");
            break;
          default:
            break;
        }
        setInputs({
          ...inputs,
          gedragq9Tekst: g9Tekst,
          gedragq9: inputG9,
          G9Calc: inputG9,
          gedragq10: inputG10,
          G10Calc: inputG10,
          ernstq9: inputE9,
          E10Calc: inputE9,
        });

        break;
      default:
        switch (e.target.id) {
          case "ernstq1":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E1Calc: ((e.target.value * 1) / 10) * multipliers.E1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq2":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E2Calc: ((e.target.value * 1) / 10) * multipliers.E2,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq3":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E3Calc: inputs.E2Calc * (((e.target.value * 1) / 10) * multipliers.E3),
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq4":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E4Calc: ((e.target.value * 1) / 10) * multipliers.E4,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq5":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E5Calc: inputs.E4Calc * ((e.target.value * 1) / 10) * multipliers.E5,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq6":
            if (ernstq1 <= 5 && ernstq6 * 1 > ernstq2 * 1 + 5) {
              handleShowAlert(
                `Er is nog geen/nauwelijks schade. Je schat nu de kans op ziekte en sterfte groter in  ` +
                  `dan de kans dat er alsnog schade ontstaat (2e vraag). Dat lijkt onlogisch.  \n \n` +
                  ` Ga alleen door als je een bewuste keuze maakt.`
              );
            }
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E6Calc: ((e.target.value * 1) / 10) * multipliers.E6,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq7":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E7Calc:
                ((inputs.E6Calc * 1 * (inputs.ernstq7a * 1) * (e.target.value * 1)) / 10) *
                multipliers.E7,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq8":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E8Calc: ((e.target.value * 1) / 10) * multipliers.E8,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq1":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G1Calc:
                (((e.target.value * 1) / 10) * multipliers.G1 * escoreCalcTotal).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq2":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G2Calc: (((e.target.value * 1) / 10) * multipliers.G2 * G1Calc).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq3":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G3Calc: (((100 - e.target.value * 1) / 10) * multipliers.G3).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq4":
            if (((e.target.value * 1) / 10) * multipliers.G4 + G5Calc * 1 > 25) {
              maxed = 25;
            } else {
              maxed = (((e.target.value * 1) / 10) * multipliers.G4 + G5Calc).toFixed(1) * 1;
            }
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G4Calc: (((e.target.value * 1) / 10) * multipliers.G4).toFixed(1) * 1,
              G4G5Maxed: maxed,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "G4Toelichting":
            setInputs({ ...inputs, [e.target.name]: e.target.value });
            break;
          case "gedragq5":
            if (gedragq3 * 1 < 5 && gedragq5 > 25) {
              handleShowAlert(
                `Als de overtreder niet/nauwelijks (${gedragq3}%) ` +
                  `wist dat er sprake was van een overtreding dan is het onwaarschijnlijk dat` +
                  ` is gepoogd te voorkomen dat deze overtreding werd gezien.` +
                  ` Ga alleen door als je een bewuste keuze maakt.`
              );
            }
            if (((e.target.value * 1) / 10) * multipliers.G5 + G4Calc * 1 > 25) {
              maxed = 25;
            } else {
              maxed = (((e.target.value * 1) / 10) * multipliers.G5 + G4Calc).toFixed(1) * 1;
            }
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G5Calc: (((e.target.value * 1) / 10) * multipliers.G5).toFixed(1) * 1,
              G4G5Maxed: maxed,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq6":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G6Calc: (((e.target.value * 1) / 10) * multipliers.G6).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          /*case "gedragq7":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G7Calc:
                (((e.target.value * 1) / 10) * multipliers.G7).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
            */
          default:
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
            });
            break;
        }
        break;
    }
  };

  const onSubmitForm = async (e) => {
    setDisableSend(true);
    e.preventDefault();
    try {
      const endTime = Date.now();

      inputs.escoreTotal = escoreCalcTotal;
      inputs.gscoreTotal = gscoreCalcTotal;
      inputs.gscoreLetter = gScoreLetter;
      inputs.escoreCijfer = eScoreCijfer;
      inputs.startTijd = startTime;
      inputs.eindTijd = endTime;
      const response = await fetch(`${backendURL}forms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify(inputs),
      });

      if (response.status === 200) {
        alert(`Rapport indienden geslaagd.\nEmail verstuurd naar het bij ons bekende email adres`);

        history.push({
          pathname: "/newform",
          search: `?locatie=${inputs.locatie}&gemeente=${inputs.gemeente}&zaaknummer=${inputs.zaaknummer}`,
        });
      } else {
        alert("Er is een fout opgetreden probeer het opnieuw");
      }
    } catch (error) {
      let existingReports = JSON.parse(localStorage.getItem("rapporten"));
      if (existingReports == null) {
        existingReports = [];
      }
      existingReports.push(inputs);
      localStorage.setItem("rapporten", JSON.stringify(existingReports));

      alert(`Rapport is lokaal opgeslagen, deze wordt verzonden wanneer de verbinding hersteld is`);

      history.push({
        pathname: "/newform",
        search: `?locatie=${inputs.locatie}&gemeente=${inputs.gemeente}&zaaknummer=${inputs.zaaknummer}`,
      });
    }
  };

  const goToStep = (step) => {
    switch (step) {
      case 2:
        if (waitingServiceWorker) {
          console.log("Update beschikbaar");
          setUpdateAvailable(true);
        }
        setStep(2);
        window.scrollTo(0, 0);
        break;
      case 3:
        setStep(3);
        window.scrollTo(0, 0);
        break;
      case 4:
        setStep(4);
        window.scrollTo(0, 0);
        break;
      case 5:
        setStep(5);
        calcTotals();
        window.scrollTo(0, 0);
        break;
      default:
        break;
    }
  };

  // Check invoer velden stap1
  useEffect(() => {
    if (
      constDat.length > 0 &&
      medewerker.length > 0 &&
      locatie.length > 0 &&
      zaaknummer.length > 0 &&
      omschrijving.length > 0 &&
      gemeente.length > 0
    ) {
      setNextButton(1);
    } else {
      setNextButton("");
    }
  }, [constDat, medewerker, locatie, zaaknummer, omschrijving, gemeente]);

  // Check invoer velden stap2 (ernst)
  let noernstq7a;
  useEffect(() => {
    if (ernstq6 == 0) {
      noernstq7a = true;
    }

    if (
      ernstq1 !== "" &&
      ernstq2 !== "" &&
      ernstq3 !== "" &&
      ernstq4 !== "" &&
      ernstq5 !== "" &&
      ernstq6 !== "" &&
      ernstq7 !== "" &&
      (ernstq7a !== "" || noernstq7a === true) &&
      ernstq8 !== ""
    ) {
      setEscoreCalcTotal((E1Calc + E3Calc + E5Calc + E7Calc) * E8Calc);
      setNextButton(2);
    } else {
      setNextButton("");
    }
  }, [
    ernstq1,
    ernstq2,
    ernstq3,
    ernstq4,
    ernstq5,
    ernstq6,
    ernstq7,
    ernstq7a,
    ernstq8,
    E1Calc,
    E3Calc,
    E5Calc,
    E7Calc,
    E8Calc,
    E10Calc,
  ]);

  useEffect(() => {
    setEscoreCalcTotal((E1Calc + E3Calc + E5Calc + E7Calc) * E8Calc + E10Calc);
    setGscoreCalcTotal(37.5 + G2Calc + G3Calc + G4G5Maxed + G6G7Maxed + G8Calc + G9Calc);
    setInputs({
      ...inputs,
      G1Calc: (((gedragq1 * 1) / 10) * multipliers.G1 * escoreCalcTotal).toFixed(1) * 1,
    });
  }, [
    G2Calc,
    G3Calc,
    G4G5Maxed,
    G6G7Maxed,
    G8Calc,
    G9Calc,
    G10Calc,
    E10Calc,
    escoreCalcTotal,
    G1Calc,
  ]);

  useEffect(() => {
    setInputs({
      ...inputs,
      G2Calc: (((gedragq2 * 1) / 10) * multipliers.G2 * G1Calc).toFixed(1) * 1,
    });
  }, [G1Calc]);

  let toelAfwG = true;
  let toelAfwE = true;

  // Check invoer afwijkende waarden
  useEffect(() => {
    if (
      gAfwijking.length > 0 &&
      (gAfwijkingToelichting == undefined || gAfwijkingToelichting === "")
    ) {
      toelAfwG = false;
      setDisableSend(true);
    }
    if (
      eAfwijking.length > 0 &&
      (eAfwijkingToelichting == undefined || eAfwijkingToelichting === "")
    ) {
      toelAfwE = false;
      setDisableSend(true);
    }

    if (toelAfwG === true && toelAfwE === true) {
      setDisableSend(false);
    }
  }, [gAfwijking, eAfwijking, gAfwijkingToelichting, eAfwijkingToelichting, toelAfwG, toelAfwE]);

  // Check invoer velden stap3 (gedrag)
  let toelG4 = true;
  let toelG6 = true;

  useEffect(() => {
    if (step === 3) {
      // wanneer waarde gedragq4 groter dan 50 moet ook toelichting gevuld worden
      if (gedragq4 * 1 > 50 && G4Toelichting == "") {
        toelG4 = false;
        setNextButton(2);
      }

      // wanneer waarde gedragq6 groter dan 50 moet ook toelichting gevuld worden
      if (gedragq6 * 1 >= 25 && G6Toelichting == "") {
        toelG6 = false;
        setNextButton(2);
      }

      if (
        gedragq1 !== "" &&
        gedragq2 !== "" &&
        gedragq3 !== "" &&
        gedragq4 !== "" &&
        gedragq5 !== "" &&
        gedragq6 !== "" &&
        gedragq7 !== "" &&
        toelG4 === true &&
        toelG6 === true
      ) {
        setNextButton(3);
        setGscoreCalcTotal(37.5 + G2Calc + G3Calc + G4G5Maxed + G6G7Maxed + G8Calc + G9Calc);
      }
    }
  }, [
    gedragq1,
    gedragq2,
    gedragq3,
    gedragq4,
    gedragq5,
    gedragq6,
    gedragq7,
    G2Calc,
    G3Calc,
    G4G5Maxed,
    G6G7Maxed,
    G8Calc,
    G9Calc,
    G10Calc,
    E10Calc,
    G4Toelichting,
    G6Toelichting,
  ]);

  useEffect(() => {
    if (step === 4) {
      setGscoreCalcTotal(37.5 + G2Calc + G3Calc + G4G5Maxed + G6G7Maxed + G8Calc + G9Calc);
    }
  }, [G2Calc, G3Calc, G4G5Maxed, G6G7Maxed, G8Calc, G9Calc, G10Calc, E10Calc]);

  // Check invoer velden stap4 (gedrag)
  useEffect(() => {
    if (step === 4) {
      //console.log(G6G7Maxed);
      if (gedragq8 !== "" && gedragq9 !== "" && gedragq10 !== "") {
        setGscoreCalcTotal(37.5 + G2Calc + G3Calc + G4G5Maxed + G6G7Maxed + G8Calc + G9Calc);
        setNextButton(4);
      }
    }
  }, [gedragq8, gedragq9, gedragq10, G6G7Maxed]);

  // Temp log van gscoreTotal

  // G6 + G7 maxed bepalen
  useEffect(() => {
    let value =
      G10Calc - E10Calc + G6Calc + G7Calc > 50 ? 50 : G6Calc + G7Calc + G10Calc - 0.5 * G10Calc;
    setG6G7Maxed(value);
  }, [G10Calc, E10Calc, G6Calc, G7Calc]);

  useEffect(() => {
    onOpenForm();
  }, []);

  function goHome() {
    history.push("/");
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button className="btn btn-outline-secondary btn-md" onClick={() => goHome()}>
            <Icon path={mdiHome} size={1} />
          </button>
        </div>
      </div>
      <div className="container d-flex justify-content-center">
        <div className="col-md-10 login-form my-3">
          <form onSubmit={onSubmitForm}>
            {step === 1 && (
              <>
                <h2 className="text-center my-5 h3">Algemene gegevens</h2>
                <div className="form-group row">
                  <label htmlFor="constDat" className="col-sm-4 col-form-label">
                    Datum constatering
                    <a
                      className="modal-info-tip"
                      /*data-tooltip="De datum waarop de vastgelegde situatie werd waargenomen"*/
                      /*className="toolTipRight"*/
                      onClick={() =>
                        handleShowInfo1("De datum waarop de vastgelegde situatie werd waargenomen")
                      }
                    >
                      <Icon path={mdiInformationVariant} size={0.9} />
                    </a>
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      name="constDat"
                      value={constDat}
                      onChange={(e) => onChange(e)}
                      type="date"
                      max={curDate}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="userId" className="col-sm-4 col-form-label">
                    Medewerker
                  </label>
                  <div className="col-sm-8">
                    <input
                      name="medewerker"
                      value={medewerker}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
                <h2 className="text-center my-5">De overtreding zelf</h2>
                <div className="form-group row">
                  <div className="form-group col-md-12 col-sm-12 col-lg-6">
                    <label htmlFor="locatie">
                      Op welke locatie is de overtreding geconstateerd?
                    </label>
                    <input
                      className="form-control"
                      name="locatie"
                      value={locatie}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-3">
                    <label htmlFor="gemeente">Gemeente</label>
                    <select
                      className="form-control"
                      value={gemeente}
                      name="gemeente"
                      id="gemeente"
                      onChange={(e) => onChange(e)}
                    >
                      <option value=""></option>
                      {gemeenten.map((gemeente) => (
                        <option value={gemeente.pkey} key={gemeente.pkey}>
                          {gemeente.gemeente}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-3">
                    <label htmlFor="zaaknummer">Zaaknummer</label>
                    <InputMask
                      mask={znoMask}
                      maskChar=" "
                      onChange={(e) => onChange(e)}
                      name="zaaknummer"
                      value={zaaknummer}
                      placeholder={znoPlaceholder}
                      className="form-control"
                      type="tel"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="omschrijving">
                      Geef een bondige omschrijving van de geconstateerde overtreding (max. 400
                      tekens)
                    </label>
                    <textarea
                      name="omschrijving"
                      value={omschrijving}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
                <div className={nextButton === 1 ? "" : "nav-light mt-3"}>
                  <button
                    className={
                      nextButton === 1
                        ? "btn btn-success btn-block btn-lg mb-5 "
                        : "btn btn-outline-secondary btn-block btn-lg mb-5 noHover"
                    }
                    type="button"
                    disabled={nextButton === 1 ? false : true}
                    onClick={(e) => goToStep(2)}
                  >
                    Verder
                  </button>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <h2 className="text-center my-5 h3">
                  De vragen (ernst)
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "In de onderstaande vragen gaat het om de schade die is ontstaan of nog kan ontstaan op het moment van de controle.  Onder 'schade' wordt verstaan het ontstaan van maatschappelijke onrust en/of feitelijke schade aan milieu, natuur of water en/of aan mens, dier of plant."
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </h2>
                <h3 className="my-2 h4">Schade aan milieu, water en/of natuur</h3>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="ernstq1">
                      Wat is de omvang van de <u>al onstane</u> mileu/water en natuurschade?
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Nihil</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block mt-4">Nihil</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.ernstq1}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{ernstq1}</span>
                          </div>
                          <input
                            type="range"
                            name="ernstq1"
                            value={ernstq1 === "" ? 0 : ernstq1}
                            className={sliderStyle.ernstq1}
                            id="ernstq1"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block mt-4">Enorm</div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-md-12">
                    <label htmlFor="ernstq2">
                      Hoe groot is de kans dat er alsnog of grotere schade ontstaat?
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">0%</div>
                      <div className="ml-auto d-flex justify-content-end my-1">100%</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">0%</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.ernstq2}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{ernstq2}</span>
                          </div>
                          <input
                            type="range"
                            name="ernstq2"
                            value={ernstq2 === "" ? 0 : ernstq2}
                            className={sliderStyle.ernstq2}
                            id="ernstq2"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">100%</div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-md-12">
                    <label>
                      <u>Als</u> er nog extra schade ontstaat hoe groot zal die dan waarschijnlijk
                      zijn?
                      <a
                        className="modal-info-tip"
                        onClick={() =>
                          handleShowInfo1(
                            "Let op het gaat hier over de omvang <b>áls</b> er schade ontstaat. Dus niet -zoals bij de vorige vraag- over de <i>kans</i> dat die schade ontstaat."
                          )
                        }
                      >
                        <Icon path={mdiInformationVariant} size={0.9} />
                      </a>
                    </label>
                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Nihil</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Nihil</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.ernstq3}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{ernstq3}</span>
                          </div>
                          <input
                            type="range"
                            name="ernstq3"
                            value={ernstq3 === "" ? 0 : ernstq3}
                            className={sliderStyle.ernstq3}
                            id="ernstq3"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Enorm</div>
                    </div>
                  </div>
                </div>
                <h3 className="my-2 h4">Maatschappelijke onrust</h3>
                <div className="form-group row">
                  <div className="form-group col-md-12">
                    <label>
                      Als er schade is of komt, hoe groot is dan de kans dat dit maatschappelijke
                      onrust oplevert?
                      <a
                        className="modal-info-tip"
                        onClick={() =>
                          handleShowInfo1(
                            "Denk aan demonstraties, media aandacht, paniek, verlies van vertrouwen in overheid"
                          )
                        }
                      >
                        <Icon path={mdiInformationVariant} size={0.9} />
                      </a>
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">0%</div>
                      <div className="ml-auto d-flex justify-content-end my-1">
                        100% / Die is er al
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">0%</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.ernstq4}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{ernstq4}</span>
                          </div>
                          <input
                            type="range"
                            name="ernstq4"
                            value={ernstq4 === "" ? 0 : ernstq4}
                            className={sliderStyle.ernstq4}
                            id="ernstq4"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">100% / Die is er al</div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-md-12">
                    <label htmlFor="ernstq5">
                      <u>Als</u> er onrust komt, hoe groot zal die dan zijn?
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Nihil</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Nihil</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.ernstq5}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{ernstq5}</span>
                          </div>
                          <input
                            type="range"
                            name="ernstq5"
                            value={ernstq5 === "" ? 0 : ernstq5}
                            className={sliderStyle.ernstq5}
                            id="ernstq5"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Enorm</div>
                    </div>
                  </div>
                </div>
                <h3 className="my-2 h4">Doden, zieken of gewonden</h3>
                <div className="form-group row">
                  <div className="form-group col-md-12">
                    <label htmlFor="ernstq6">
                      Hoe groot is de kans dat mensen, dieren of planten gewond raken, ziek worden
                      of sterven?
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">0%</div>
                      <div className="ml-auto d-flex justify-content-end my-1">
                        100% / Dat is al zo
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">0%</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.ernstq6}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{ernstq6}</span>
                          </div>
                          <input
                            type="range"
                            name="ernstq6"
                            value={ernstq6 === "" ? 0 : ernstq6}
                            className={sliderStyle.ernstq6}
                            id="ernstq6"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">100% / Dat is al zo</div>
                    </div>
                  </div>
                </div>

                {ernstq6 !== "" && ernstq6 > 0 && (
                  <>
                    <p>Het betreft dan:</p>
                    <div className="form-group row">
                      <div className="form-group col-sm-12">
                        <input
                          type="radio"
                          className="btn-check"
                          name="ernstq7a"
                          id="plantDier"
                          autoComplete="off"
                          hidden
                          onClick={(e) => onChange(e, "ernstq7a")}
                        />
                        <label
                          className={`btn ${
                            ernstq7aClass === "plantDier" ? "btn-success" : "btn-outline-secondary"
                          } col-sm-4`}
                          htmlFor="plantDier"
                        >
                          Plant en dier
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="ernstq7a"
                          id="mens"
                          autoComplete="off"
                          hidden
                          onClick={(e) => onChange(e, "ernstq7a")}
                        />
                        <label
                          className={`btn ${
                            ernstq7aClass === "mens" ? "btn-success" : "btn-outline-secondary"
                          } col-sm-4`}
                          htmlFor="mens"
                        >
                          Mens
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="ernstq7a"
                          id="mensPlantDier"
                          autoComplete="off"
                          hidden
                          onClick={(e) => onChange(e, "ernstq7a")}
                        />
                        <label
                          className={`btn ${
                            ernstq7aClass === "mensPlantDier"
                              ? "btn-success"
                              : "btn-outline-secondary"
                          } col-sm-4`}
                          htmlFor="mensPlantDier"
                        >
                          Mens, plant en dier
                        </label>
                      </div>
                    </div>
                  </>
                )}
                <div className="form-group row">
                  <div className="form-group col-md-12">
                    <label>
                      <u>Als</u> dat gebeurt, wat is dan de meest waarschijnlijke omvang (aantallen
                      en ernst) daarvan?
                      <a
                        className="modal-info-tip"
                        onClick={() =>
                          handleShowInfo1(
                            "Let op het gaat hier over de omvang van schade <b>áls</b> mensen, dieren of planten gewond raken, ziek worden of sterven. Dus niet -zoals bij de vorige vraag- over de <i>kans</i> dat dat gebeurt."
                          )
                        }
                      >
                        <Icon path={mdiInformationVariant} size={0.9} />
                      </a>
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Nihil</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Nihil</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.ernstq7}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{ernstq7}</span>
                          </div>
                          <input
                            type="range"
                            name="ernstq7"
                            value={ernstq7 === "" ? 0 : ernstq7}
                            className={sliderStyle.ernstq7}
                            id="ernstq7"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Enorm</div>
                    </div>
                  </div>
                </div>
                <h3 className="my-2 h4">Tenslotte: ongedaan maken van schade?</h3>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="ernstq8">Kan de schade hersteld worden?</label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Volledig</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Helemaal niet</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Volledig</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.ernstq8}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{ernstq8}</span>
                          </div>
                          <input
                            type="range"
                            name="ernstq8"
                            value={ernstq8 === "" ? 0 : ernstq8}
                            className={sliderStyle.ernstq8}
                            id="ernstq8"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Helemaal niet</div>
                    </div>
                  </div>
                </div>

                <button
                  className={
                    nextButton === 2
                      ? "btn btn-success btn-block btn-lg"
                      : "btn btn-secondary btn-block btn-lg"
                  }
                  type="button"
                  disabled={nextButton === 2 ? false : true}
                  onClick={(e) => goToStep(3)}
                >
                  Verder
                </button>
                <br />
              </>
            )}
            {step === 3 && (
              <>
                <h2 className="text-center my-5 h3">De vragen (gedrag)</h2>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="gedragq1">
                      Hoe voorspelbaar was het op voorhand, voor jou als deskundige, dat er schade
                      zou (kunnen) zou ontstaan?
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">
                        Geheel onwaarschijnlijk
                      </div>
                      <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Geheel onwaarschijnlijk</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.gedragq1}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{gedragq1}</span>
                          </div>
                          <input
                            type="range"
                            name="gedragq1"
                            value={gedragq1 === "" ? 0 : gedragq1}
                            className={sliderStyle.gedragq1}
                            id="gedragq1"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Nagenoeg zeker</div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="gedragq2">
                      Had van de overtreder verwacht mogen worden dat deze dezelfde inschatting zou
                      maken?
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Zeker niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.gedragq2}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{gedragq2}</span>
                          </div>
                          <input
                            type="range"
                            name="gedragq2"
                            value={gedragq2 === "" ? 0 : gedragq2}
                            className={sliderStyle.gedragq2}
                            id="gedragq2"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="gedragq3">
                      Wist de overtreder al dat er sprake was van een overtreding?
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Zeker niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.gedragq3}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{gedragq3}</span>
                          </div>
                          <input
                            type="range"
                            name="gedragq3"
                            value={gedragq3 === "" ? 0 : gedragq3}
                            className={sliderStyle.gedragq3}
                            id="gedragq3"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="gedragq4">
                      De overtreder heeft de overtreding begaan om voordeel te behalen.
                    </label>
                    <a
                      className="modal-info-tip"
                      onClick={() =>
                        handleShowInfo1("Hoeft niet per se een financieel voordeel te zijn.")
                      }
                    >
                      <Icon path={mdiInformationVariant} size={0.9} />
                    </a>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Zeker niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.gedragq4}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{gedragq4}</span>
                          </div>
                          <input
                            type="range"
                            name="gedragq4"
                            value={gedragq4 === "" ? 0 : gedragq4}
                            className={sliderStyle.gedragq4}
                            id="gedragq4"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>

                {G4Calc >= 8.8 && (
                  <div className="form-group row">
                    <div className="form-group col-sm-12">
                      <textarea
                        name="G4Toelichting"
                        value={G4Toelichting}
                        id="G4Toelichting"
                        placeholder="Geef aan welke indicaties je hebt"
                        onChange={(e) => onChange(e)}
                        className="form-control"
                        rows="3"
                      />
                    </div>
                  </div>
                )}

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="gedragq5">
                      De overtreder probeerde te voorkomen dat de overtreding werd gezien.
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Zeker niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.gedragq5}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{gedragq5}</span>
                          </div>
                          <input
                            type="range"
                            name="gedragq5"
                            value={gedragq5 === "" ? 0 : gedragq5}
                            className={sliderStyle.gedragq5}
                            id="gedragq5"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="gedragq6">
                      Het bedrijf is opgezet om de wet te overtreden (criminele organisatie).
                    </label>

                    {/* alleen op mobiel labels erboven tonen */}
                    <div className="d-flex flex-row d-block d-sm-none">
                      <div className="d-flex justify-content-start my-1">Zeker niet</div>
                      <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                      <div className="col-sm-8">
                        <div className="range-wrap">
                          <div
                            className={rangeValueStyle.gedragq6}
                            id="rangeV"
                            style={{ right: 12 }}
                          >
                            <span>{gedragq6}</span>
                          </div>
                          <input
                            type="range"
                            name="gedragq6"
                            value={gedragq6 === "" ? 0 : gedragq6}
                            className={sliderStyle.gedragq6}
                            id="gedragq6"
                            onChange={(e) => onChange(e)}
                            onClick={(e) => onChange(e)}
                            onTouchStart={(e) => onChange(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                    </div>
                  </div>
                </div>

                {gedragq6 * 1 >= 25 && (
                  <div className="form-group row">
                    <div className="form-group col-sm-12">
                      <textarea
                        name="G6Toelichting"
                        value={G6Toelichting}
                        id="G6Toelichting"
                        placeholder="Geef aan welke indicaties je hebt"
                        onChange={(e) => onChange(e)}
                        className="form-control"
                        rows="3"
                      />
                    </div>
                  </div>
                )}

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="gedragq7">
                      Meerdere handhavende instanties treden op in de richting van dit bedrijf.
                    </label>

                    <div className="form-group row">
                      <div className="form-group col-sm-12">
                        <input
                          type="radio"
                          className="btn-check"
                          name="gedragq7"
                          id="gedragq7Ja"
                          autoComplete="off"
                          hidden
                          onChange={(e) => onChange(e, "gedragq7")}
                          onClick={(e) => onChange(e, "gedragq7")}
                        />
                        <label
                          className={`btn ${
                            gedragq7Class === "gedragq7Ja" ? "btn-success" : "btn-outline-secondary"
                          }  col-sm-4`}
                          htmlFor="gedragq7Ja"
                        >
                          Ja
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="gedragq7"
                          id="gedragq7Nee"
                          autoComplete="off"
                          hidden
                          onChange={(e) => onChange(e, "gedragq7")}
                          onClick={(e) => onChange(e, "gedragq7")}
                        />
                        <label
                          className={`btn ${
                            gedragq7Class === "gedragq7Nee"
                              ? "btn-success"
                              : "btn-outline-secondary"
                          }  col-sm-4`}
                          htmlFor="gedragq7Nee"
                        >
                          Nee
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="gedragq7"
                          id="gedragq7Onbekend"
                          autoComplete="off"
                          hidden
                          onChange={(e) => onChange(e, "gedragq7")}
                          onClick={(e) => onChange(e, "gedragq7")}
                        />
                        <label
                          className={`btn ${
                            gedragq7Class === "gedragq7Onbekend"
                              ? "btn-success"
                              : "btn-outline-secondary"
                          }  col-sm-4`}
                          htmlFor="gedragq7Onbekend"
                        >
                          Niet bekend
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/*onderstaande knop moet de ingevulde bovenstaande waarden verifieren, wanneer ook bovenstaand blok verbergen en volgende blok tonen */}
                <button
                  className={
                    nextButton === 3
                      ? "btn btn-success btn-block btn-lg"
                      : "btn btn-secondary btn-block btn-lg"
                  }
                  type="button"
                  disabled={nextButton === 3 ? false : true}
                  onClick={(e) => goToStep(4)}
                >
                  Verder
                </button>
                <br />
              </>
            )}
            {step === 4 && (
              <>
                <h2 className="text-center my-5 h3">
                  De omstandigheden (verzachtend / verzwarend)
                </h2>

                <p>Is legalisatie van de overtreding een mogelijkheid?</p>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <input
                      type="radio"
                      className="btn-check"
                      name="gedragq8"
                      id="legalJa"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gedragq8")}
                      onClick={(e) => onChange(e, "gedragq8")}
                    />
                    <label
                      className={`btn ${
                        gedragq8Class === "legalJa" ? "btn-success" : "btn-outline-secondary"
                      }  col-sm-4`}
                      htmlFor="legalJa"
                    >
                      Ja
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gedragq8"
                      id="legalNee"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gedragq8")}
                      onClick={(e) => onChange(e, "gedragq8")}
                    />
                    <label
                      className={`btn ${
                        gedragq8Class === "legalNee" ? "btn-success" : "btn-outline-secondary"
                      }  col-sm-4`}
                      htmlFor="legalNee"
                    >
                      Nee
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gedragq8"
                      id="legalNvt"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gedragq8")}
                      onClick={(e) => onChange(e, "gedragq8")}
                    />
                    <label
                      className={`btn ${
                        gedragq8Class === "legalNvt" ? "btn-success" : "btn-outline-secondary"
                      }  col-sm-4`}
                      htmlFor="legalNvt"
                    >
                      NVT (milieu)
                    </label>
                  </div>
                </div>

                <p>Is dezelfde overtreding al eerder geconstateerd bij dit bedrijf?</p>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <input
                      type="radio"
                      className="btn-check"
                      name="gedragq9"
                      id="G9Nee"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gedragq9")}
                    />
                    <label
                      className={`btn ${
                        gedragq9Class === "G9Nee" ? "btn-success" : "btn-outline-secondary"
                      }  col-sm-4`}
                      htmlFor="G9Nee"
                    >
                      Nee
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gedragq9"
                      id="G9JaEenmaal"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gedragq9")}
                    />
                    <label
                      className={`btn ${
                        gedragq9Class === "G9JaEenmaal" ? "btn-success" : "btn-outline-secondary"
                      }  col-sm-4`}
                      htmlFor="G9JaEenmaal"
                    >
                      Ja eenmaal
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gedragq9"
                      id="G9JaMeermaal"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gedragq9")}
                    />
                    <label
                      className={`btn ${
                        gedragq9Class === "G9JaMeermaal" ? "btn-success" : "btn-outline-secondary"
                      }  col-sm-4`}
                      htmlFor="G9JaMeermaal"
                    >
                      Ja meermalen
                    </label>
                  </div>
                </div>

                {/*onderstaande knop moet de ingevulde bovenstaande waarden verifieren, wanneer ook bovenstaand blok verbergen en volgende blok tonen */}
                <button
                  className={
                    nextButton === 4
                      ? "btn btn-success btn-block btn-lg"
                      : "btn btn-secondary btn-block btn-lg"
                  }
                  type="button"
                  disabled={nextButton === 4 ? false : true}
                  onClick={(e) => goToStep(5)}
                >
                  Verder
                </button>
                <br />
              </>
            )}
            {step === 5 && (
              <>
                <h2 className="text-center my-5 h3">Het resultaat</h2>

                <p>
                  Volgens de gehanteerde methodiek dient de overtreding gekwalificeerd te worden in
                  categorie:{" "}
                  <b>
                    {gScoreLetter}
                    {eScoreCijfer} (G: {gscoreCalcTotal.toFixed(2)}, E: {escoreCalcTotal.toFixed(2)}
                    ){" "}
                  </b>
                  <br />
                  Als je dat niet vindt, geeft dan hier aan waar je afwijkt van de scores.
                </p>

                <p>Gedrag</p>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <input
                      type="radio"
                      className="btn-check"
                      name="gAfwijking"
                      id="gAfwA"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gAfwijking")}
                      onClick={(e) => onChange(e, "gAfwijking")}
                      disabled={gScoreLetter !== "A" ? false : true}
                    />
                    <label
                      className={`btn ${
                        gAfwijkingClass === "gAfwA" ? "btn-success" : "btn-outline-secondary"
                      } ${gScoreLetter === "A" ? "btn-secondary" : ""}  col-sm-3`}
                      htmlFor="gAfwA"
                    >
                      A
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gAfwijking"
                      id="gAfwB"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gAfwijking")}
                      onClick={(e) => onChange(e, "gAfwijking")}
                      disabled={gScoreLetter !== "B" ? false : true}
                    />
                    <label
                      className={`btn ${
                        gAfwijkingClass === "gAfwB" ? "btn-success" : "btn-outline-secondary"
                      } ${gScoreLetter === "B" ? "btn-secondary" : ""}  col-sm-3`}
                      htmlFor="gAfwB"
                    >
                      B
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gAfwijking"
                      id="gAfwC"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gAfwijking")}
                      onClick={(e) => onChange(e, "gAfwijking")}
                      disabled={gScoreLetter !== "C" ? false : true}
                    />
                    <label
                      className={`btn ${
                        gAfwijkingClass === "gAfwC" ? "btn-success" : "btn-outline-secondary"
                      } ${gScoreLetter === "C" ? "btn-secondary" : ""}  col-sm-3`}
                      htmlFor="gAfwC"
                    >
                      C
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gAfwijking"
                      id="gAfwD"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "gAfwijking")}
                      onClick={(e) => onChange(e, "gAfwijking")}
                      disabled={gScoreLetter !== "D" ? false : true}
                    />
                    <label
                      className={`btn ${
                        gAfwijkingClass === "gAfwD" ? "btn-success" : "btn-outline-secondary"
                      } ${gScoreLetter === "D" ? "btn-secondary" : ""}  col-sm-3`}
                      htmlFor="gAfwD"
                    >
                      D
                    </label>
                  </div>
                </div>

                {gAfwijking !== "" && (
                  <div className="form-group row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="gAfwijkingToelichting">
                        Wat is doorslaggevend om <br className="d-block d-sm-none" />
                        tot de inschatting {gAfwijking} te komen?
                      </label>
                      <textarea
                        name="gAfwijkingToelichting"
                        value={gAfwijkingToelichting}
                        onChange={(e) => onChange(e)}
                        className="form-control"
                        rows="3"
                      />
                    </div>
                    <div className="row col-md-6 offset-md-3">
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-block"
                        onClick={cancelGAfwijking}
                      >
                        Annuleer afwijkende gedrag score
                      </button>
                    </div>
                  </div>
                )}

                <p>Ernst</p>

                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <input
                      type="radio"
                      className="btn-check"
                      name="eAfwijking"
                      id="eAfw1"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "eAfwijking")}
                      onClick={(e) => onChange(e, "eAfwijking")}
                      disabled={eScoreCijfer !== 1 ? false : true}
                    />
                    <label
                      className={`btn ${
                        eAfwijkingClass === "eAfw1" ? "btn-success" : "btn-outline-secondary"
                      } ${eScoreCijfer === 1 ? "btn-secondary" : ""}  col-sm-3`}
                      htmlFor="eAfw1"
                    >
                      1
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="eAfwijking"
                      id="eAfw2"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "eAfwijking")}
                      onClick={(e) => onChange(e, "eAfwijking")}
                      disabled={eScoreCijfer !== 2 ? false : true}
                    />
                    <label
                      className={`btn ${
                        eAfwijkingClass === "eAfw2" ? "btn-success" : "btn-outline-secondary"
                      } ${eScoreCijfer === 2 ? "btn-secondary" : ""}  col-sm-3`}
                      htmlFor="eAfw2"
                    >
                      2
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="eAfwijking"
                      id="eAfw3"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "eAfwijking")}
                      onClick={(e) => onChange(e, "eAfwijking")}
                      disabled={eScoreCijfer !== 3 ? false : true}
                    />
                    <label
                      className={`btn ${
                        eAfwijkingClass === "eAfw3" ? "btn-success" : "btn-outline-secondary"
                      } ${eScoreCijfer === 3 ? "btn-secondary" : ""} col-sm-3`}
                      htmlFor="eAfw3"
                    >
                      3
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="eAfwijking"
                      id="eAfw4"
                      autoComplete="off"
                      hidden
                      onChange={(e) => onChange(e, "eAfwijking")}
                      onClick={(e) => onChange(e, "eAfwijking")}
                      disabled={eScoreCijfer !== 4 ? false : true}
                    />
                    <label
                      className={`btn ${
                        eAfwijkingClass === "eAfw4" ? "btn-success" : "btn-outline-secondary"
                      }  col-sm-3 ${eScoreCijfer === 4 ? "btn-secondary" : ""}`}
                      htmlFor="eAfw4"
                    >
                      4
                    </label>
                  </div>
                </div>

                {eAfwijking !== "" && (
                  <div className="form-group row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="eAfwijkingToelichting">
                        Wat is doorslaggevend om <br className="d-block d-sm-none" /> tot de
                        inschatting {eAfwijking} te komen?
                      </label>
                      <textarea
                        name="eAfwijkingToelichting"
                        value={eAfwijkingToelichting}
                        onChange={(e) => onChange(e)}
                        className="form-control"
                        rows="3"
                      />
                    </div>
                    <div className="row col-md-6 offset-md-3">
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-block"
                        onClick={cancelEAfwijking}
                      >
                        Annuleer afwijkende ernst score
                      </button>
                    </div>
                  </div>
                )}

                <button
                  className={
                    disableSend === false
                      ? "btn btn-success btn-block btn-lg my-4"
                      : "btn btn-secondary btn-block btn-lg my-4"
                  }
                  type="submit"
                  disabled={disableSend}
                >
                  Verzenden
                </button>
              </>
            )}
          </form>
        </div>
      </div>

      <div className="d-flex flex-row d-block d-block d-sm-none">
        <div className="col-md-10 d-flex justify-content-center">
          <div className="nav-light mt-3">
            <button
              type="button"
              className="btn btn-outline-danger btn-block btn-md mt-2 mb-4"
              onClick={cancelReport}
            >
              Rapport annuleren
            </button>
          </div>
        </div>
      </div>

      <div className="row col-md-10 offset-md-1 d-none d-sm-block">
        <div className="row col-md-6 offset-md-3">
          <button
            type="button"
            className="btn btn-outline-danger btn-block my-5"
            onClick={cancelReport}
          >
            Rapport annuleren
          </button>
        </div>
      </div>

      <Modal show={showAlert} onHide={handleCloseAlert} keyboard={true} centered>
        <Modal.Header>
          <Modal.Title>
            <Icon path={mdiAlert} color="#d9534f" size={1.6} />
            <span className="glyphtext-right">{"  "}Waarschuwing</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="form-group row">
              <div className="col-12">{alertText}</div>
            </div>
            <div className="form-group row"></div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary btn-sm" onClick={handleCloseAlert}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showInfo1} onHide={handleCloseInfo1} keyboard={true} centered>
        <Modal.Header>
          <Modal.Title>
            <Icon path={mdiInformation} color="#006fb4" size={1.6} />
            <span className="glyphtext-right">{"  "}Informatie</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="form-group row">
              <div dangerouslySetInnerHTML={{ __html: infoText }} className="col-12"></div>
            </div>
            <div className="form-group row"></div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary btn-sm" onClick={handleCloseInfo1}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
